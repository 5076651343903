import React from 'react'
import { initializeApp } from 'firebase/app'
import { getAuth, signInWithPopup, FacebookAuthProvider } from 'firebase/auth'
import { FirebaseConfig, FirebaseConfigProduction } from './FirebaseConfig'



const FirebaseAuth = () => {
  
  const [config, setConfig] = React.useState('prod')

  const doLogin = () => {
    console.log('Login')
    const app = initializeApp(config === 'test' ? FirebaseConfig : FirebaseConfigProduction);
    // const app = initializeApp(FirebaseConfigProduction);
    const auth = getAuth(app)
    const provider = new FacebookAuthProvider()
    // provider.addScope('default_profile')
    // provider.addScope('email')
    // provider.setCustomParameters({
    //   'display': 'popup'
    // })
    signInWithPopup(auth, provider).then(result => {
      console.log('OK', result)
      const credential = FacebookAuthProvider.credentialFromResult(result)
      console.log('cred', credential)
    })
  }

  return (
    <div>
      <div>Firebase Auth Component.({config})</div>
      <div><button type="button" onClick={doLogin}>Login</button></div>
    </div>
  )
}
export default FirebaseAuth
