export const FirebaseConfig = {
  apiKey: "AIzaSyDN6MqzmBLwkBywambYJjWKKmtyoRA1IqA",
  authDomain: "paike-dev-01.firebaseapp.com",
  databaseURL: "https://paike-dev-01.firebaseio.com",
  projectId: "paike-dev-01",
  storageBucket: "paike-dev-01.appspot.com",
  messagingSenderId: "370793139477",
  appId: "1:370793139477:web:98c76dd2d0fa5cceca2d1c",
  measurementId: "G-6WQ52SCNEX"
};
export const FirebaseConfigProduction = {
  apiKey: "AIzaSyAwIPsUYVzAxFgnFTue1m19zk7xwJvsky4",
  authDomain: "paikeout2.firebaseapp.com",
  databaseURL: "https://paikeout2.firebaseio.com",
  projectId: "paikeout2",
  storageBucket: "paikeout2.appspot.com",
  messagingSenderId: "338632569865",
  appId: "1:338632569865:web:193c58721bf1e67910f4d8",
  measurementId: "G-N0VPV1DPXG"
}